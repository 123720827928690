import React, { useEffect } from "react"
import { withPrefix } from "gatsby-link"
import Metatags from "../components/metatags"

const RedirectIndex = () => {

  useEffect(() => {
    const availableLanguages = ["en", "es", "pt"]
    let langKey = ""
    // search for browser languages
    for (const element of window.navigator.languages) {
      if (availableLanguages.includes(element.substring(0, 2))) {
        langKey = element.substring(0, 2)
        break
      }
    }
    // defaults to English
    if (langKey === "") langKey = "en"
    const homeUrl = withPrefix(`/${langKey}/`)
    window.location.replace(homeUrl)
  }, [])

  return (
      <Metatags
        title="NetBistrot - Digital agency"
        description="Great websites for small business."
        url="https://netbistrot.com/"
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language="en"
      />
  )
}

export default RedirectIndex
